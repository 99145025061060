// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-como-llegar-js": () => import("./../../src/pages/como-llegar.js" /* webpackChunkName: "component---src-pages-como-llegar-js" */),
  "component---src-pages-contacto-js": () => import("./../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-casa-js": () => import("./../../src/pages/la-casa.js" /* webpackChunkName: "component---src-pages-la-casa-js" */),
  "component---src-pages-legales-js": () => import("./../../src/pages/legales.js" /* webpackChunkName: "component---src-pages-legales-js" */),
  "component---src-pages-lugares-js": () => import("./../../src/pages/lugares.js" /* webpackChunkName: "component---src-pages-lugares-js" */),
  "component---src-pages-segunda-casa-js": () => import("./../../src/pages/segunda-casa.js" /* webpackChunkName: "component---src-pages-segunda-casa-js" */)
}

